<div
  class="d-flex"
  [class.compact]="props.compact === true"
  [ngClass]="{'pb-2': !props.resetPaddingBottom}"
>
  <label *ngIf="!props.hideLabel" class="label" [attr.for]="id" [attr.data-id]="id+'-label'">
    <typography look="formLabel">{{ props.label }}</typography>
    <typography
      look="small"
      *ngIf="props.required && props.hideRequiredMarker !== true"
      class="required"
      >*</typography
    >
  </label>

  <div class="d-flex align-items-center flex-column">
    <div class="d-flex align-items-center" hoverClass>
      <ng-template #fieldComponent></ng-template>

      <ng-container *ngIf="props.showCopyToClipboard">
        <copy-to-clipboard
          *ngIf="field.props?.calculatedValue; else regularVal"
          class="ms-1 copy-tcb"
          [value]="field.props?.calculatedValue | async | formlyFieldFormattedValue : field.type"
        ></copy-to-clipboard>

        <ng-template #regularVal>
          <copy-to-clipboard
            class="ms-1 copy-tcb"
            [value]="field.formControl?.value | formlyFieldFormattedValue : field.type"
          ></copy-to-clipboard>
        </ng-template>
      </ng-container>
    </div>

    <validation-message *ngIf="showError" [field]="field"></validation-message>
  </div>
</div>
