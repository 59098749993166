<typeahead-v3
  [placeholder]="props.placeholder || ''"
  [items]="props.optionsService"
  (selectItem)="selectOption($event)"
  [leftImgTpl]="leftImg"
  [selectedItem]="selectedItem"
  [key]="field.key"
  [filterCallback]="props.filterCallback"
>
</typeahead-v3>

<ng-template #leftImg let-item="item">
  <airline-logo [logoUrl]="item.extras?.logoUrl" [size]="16" class="v2-mr-2"></airline-logo>
</ng-template>
