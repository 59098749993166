<div class="d-flex flex-column">
  <div class="d-flex align-items-center v2-mb-1">
    <div [ngClass]="{ 'col-6': props.hotelBooking }" class="col-3">
      <typography-v2 look="caption-bold">Cost Type</typography-v2>
    </div>

    <div *ngIf="!props.hotelBooking" class="d-flex col-3">
      <ng-container *ngIf="selectedCostType?.value === CostType.Hotel; else perHeadTemplate">
        <div class="d-flex v2-mr-4 hotel-name">
          <typography-v2 look="caption-bold">Hotel Name</typography-v2>
        </div>
        <div class="d-flex hotel-nights">
          <typography-v2 look="caption-bold">Nights</typography-v2>
        </div>
      </ng-container>

      <ng-template #perHeadTemplate>
        <typography-v2 look="caption-bold">Per</typography-v2>
      </ng-template>
    </div>

    <div [ngClass]="{ 'col-6': props.hotelBooking }" class="col-3">
      <typography-v2 look="caption-bold">Amount</typography-v2>
      <span class="text-purple v2-ml-1">*</span>
    </div>

    <div *ngIf="!props.hotelBooking" class="col-3">
      <typography-v2 look="caption-bold">Pay with Client CC</typography-v2>
    </div>
  </div>

  <div class="d-flex">
    <div [ngClass]="{ 'col-6': props.hotelBooking }" class="col-3">
      <dropdown-v2
        (itemSelect)="selectCostType($event)"
        [items]="costTypes"
        [selectedItem]="selectedCostType"
        [disabledDropdown]="form.disabled || !!props.disabled || !!props.readonly"
        placeholder="Type of Cost/Commission"
        data-id="type-of-cost"
      ></dropdown-v2>
    </div>

    <div *ngIf="!props.hotelBooking" class="d-flex align-items-center col-3">
      <ng-container *ngIf="selectedCostType?.value === CostType.Hotel; else perTemplate">
        <input
          [formControl]="hotelNameControl"
          maxlength="{{ maxHotelNameLength }}"
          placeholder="Hotel Name"
          class="text-input hotel-name v2-mr-4"
          data-id="hotel-name"
        />
        <input
          (accept)="onAccept()"
          [formControl]="hotelNightsControl"
          [imask]="imaskNumeric"
          [unmask]="true"
          placeholder="Nights"
          class="numeric-input hotel-nights"
          data-id="hotel-nights"
        />
      </ng-container>

      <ng-template #perTemplate>
        <dropdown-v2
          (itemSelect)="selectPerType($event)"
          [items]="perTypes"
          [selectedItem]="selectedPerType"
          [disabledDropdown]="form.disabled || !!props.disabled || !!props.readonly"
          placeholder="Per"
          data-id="per-type"
        ></dropdown-v2>
      </ng-template>
    </div>

    <div [ngClass]="{ 'col-6': props.hotelBooking }" class="col-3">
      <div class="currency-input-container align-items-center">
        <input
          (accept)="onAccept()"
          [formControl]="unitAmountControl"
          [imask]="imask"
          [unmask]="true"
          placeholder="Amount"
          class="currency-input"
          data-id="amount"
        />
      </div>
    </div>

    <div *ngIf="!props.hotelBooking" class="col-3">
      <dropdown-v2
        (itemSelect)="selectIsDirect($event)"
        [items]="isDirectTypes"
        [selectedItem]="selectedIsDirect"
        [disabledDropdown]="form.disabled || !!props.disabled || !!props.readonly"
        placeholder="Pay with Client CC"
        data-id="is-direct"
      ></dropdown-v2>
    </div>
  </div>
</div>
