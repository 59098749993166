<dropdown-v2
  [selectedItem]="selectedItem"
  [placeholder]="props.placeholder || ''"
  [disabledDropdown]="form.disabled || !!props.disabled || !!props.readonly"
  [items]="items"
  [isFilter]="props.isFilter"
  [wideItemsContainer]="true"
  [attr.data-id]="field.key"
  (itemSelect)="optionSelected($event)"
  (collapse)="onCollapse($event)"
></dropdown-v2>

<skeleton-loading-dropdown *ngIf="loading" class="loading-options"> </skeleton-loading-dropdown>
