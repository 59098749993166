<field-layout [isReadonly]="isReadonly">
  <editable editableView [attr.data-id]="field.dataId" [ngClass]="{'readonly': props.readonly}">
    <ng-template viewMode>
      <div class="view-mode" [ngClass]="{'input-error-border': showError}">
        <currency-value-view
          [currencyFormat]="field.props?.currencyFormat"
          [value]="formControl.value"
          [placeholder]="field.props?.placeholder"
        >
        </currency-value-view>
        <rdr-svg-icon
          class="pencil-icon me-1 ms-auto"
          key="pencil-create"
          color="var(--in-place-icon)"
        ></rdr-svg-icon>
      </div>
    </ng-template>
    <ng-template editMode>
      <input
        type="text"
        class="formly-input"
        radarOnEnter
        (radarOnEscape)="cancel()"
        radarEditableFocus
        editableOnEnter
        editableOnEscape
        [imask]="imask"
        [unmask]="true"
        [placeholder]="field.props?.placeholder"
        [formControl]="formControl"
        [formlyAttributes]="field"
      />
    </ng-template>
  </editable>

  <currency-value-view
    readonlyView
    [attr.data-id]="field.dataId"
    [currencyFormat]="field.props?.currencyFormat"
    [value]="formControl.value"
    [placeholder]="field.props?.placeholder"
  >
  </currency-value-view>
</field-layout>
