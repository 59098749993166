<field-layout [isReadonly]="isReadonly">
  <div editableView class="d-flex align-items-center">
    <editable [attr.data-id]="field.dataId">
      <ng-template viewMode>
        <div class="view-mode" [ngClass]="{'input-error-border': showError}">
          <numeric-value-view
            [value]="formControl.value"
            [placeholder]="field.props?.placeholder"
            [mask]="imaskNumeric"
          ></numeric-value-view>

          <rdr-svg-icon
            class="pencil-icon me-1 ms-auto"
            key="pencil-create"
            color="var(--in-place-icon)"
          ></rdr-svg-icon>
        </div>
      </ng-template>
      <ng-template editMode>
        <input
          [imask]="imaskNumeric"
          [unmask]="'typed'"
          type="text"
          class="formly-input"
          radarOnEnter
          (radarOnEscape)="cancel()"
          radarEditableFocus
          editableOnEnter
          editableOnEscape
          [placeholder]="field.props?.placeholder"
          [formControl]="formControl"
          [formlyAttributes]="field"
        />
      </ng-template>
    </editable>

    <div *ngIf="field.props?.calculatedValue | async as calculatedValue">
      <typography data-id="calculated-value" look="H4" class="ms-1">
        {{ calculatedValue }}
      </typography>
    </div>
  </div>

  <numeric-value-view
    readonlyView
    [attr.data-id]="field.dataId"
    [value]="formControl.value"
    [placeholder]="field.props?.placeholder"
    [mask]="imaskNumeric"
  ></numeric-value-view>
</field-layout>
