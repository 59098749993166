<chips-v2
  [items]="props.optionsService | async"
  [filterCallback]="props.filterCallback"
  [groupByField]="props.groupByField"
  [initial]="props.initial"
  [isFilter]="props.isFilter"
  [placeholder]="props.placeholder"
  [attr.data-id]="field.key"
  [disabled]="props.disabled"
  (itemSelectionChanged)="onItemSelectionChanged($event)"
>
</chips-v2>
