<input
  type="text"
  [attr.data-id]="field.dataId"
  #dateInput
  (click)="triggerDatepicker()"
  placeholder="mm/dd/yyyy"
  [formControl]="dateInputModel"
  [disabled]="!!props.disabled"
  [class.disabled]="props.disabled"
  (keydown.enter)="onDateInputChange(true)"
  (change)="onDateInputChange()"
  [ngClass]="{ 'has-error': formControl.invalid && formControl.dirty }"
  class="date date-input"
  autocomplete="off"
/>

<input-icon-container class="cursor-pointer">
  <rdr-svg-icon
    *ngIf="!formControl.value || !props.clearable"
    (click)="triggerDatepicker()"
    key="calendar"
    size="md"
  ></rdr-svg-icon>
  <rdr-svg-icon
    *ngIf="formControl.value && props.clearable"
    (click)="clearValue()"
    color="var(--color-black-50)"
    key="close"
    size="md"
  ></rdr-svg-icon>
</input-icon-container>

<bs-datepicker-inline
  *ngIf="showDatepicker"
  data-id="datepicker"
  class="v2-mt-4 d-block ms-auto bs-datepicker me-auto position-absolute"
  [bsValue]="bsModel.value"
  [bsConfig]="{
    customTodayClass: 'rdr-today'
  }"
  [minDate]="props.minDate"
  (clickOutside)="closeDatepicker()"
  (bsValueChange)="onDatepickerChange($event)"
></bs-datepicker-inline>
