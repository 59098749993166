<input
  [formControl]="formControl"
  [formlyAttributes]="field"
  [attr.placeholder]="props.placeholder || ''"
  [attr.data-id]="field.key"
  class="text-input"
  autocomplete="off"
  id="cvv"
  ccCVC
/>
