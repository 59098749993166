<div
  *ngIf="props.label"
  [ngClass]="{
    'v2-mb-4': props.biggerLabelMargin === true,
    'v2-mb-1': props.biggerLabelMargin !== true
  }"
  class="d-flex align-items-center"
>
  <typography-v2 look="caption-bold">{{ props.label }}</typography-v2>
  <span *ngIf="props.required" class="text-purple v2-ml-1">*</span>
  <div *ngIf="props.noteText" class="d-flex position-relative">
    <rdr-svg-icon
      key="alert-v-2"
      class="v2-ml-1"
      tooltip="{{ props.noteText }}"
      containerClass="radar-tooltip"
      placement="right"
    ></rdr-svg-icon>
  </div>
</div>
<ng-template #fieldComponent></ng-template>

<formly-validation-message
  *ngIf="formControl?.dirty && formControl.errors"
  [field]="field"
></formly-validation-message>
