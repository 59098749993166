<typeahead-v3
  [placeholder]="props.placeholder || ''"
  [items]="props.optionsService"
  (selectItem)="selectOption($event)"
  [disabled]="!!props.disabled"
  [loadingTemplate]="loadingTmpl"
  [emptyTemplate]="emptyTemplate"
  [customListTemplate]="customListTemplate"
  [selectedItem]="selectedItem"
  [resetSearch$]="resetField$"
  [clearable]="!!props.attributes?.clearable"
></typeahead-v3>

<ng-template #loadingTmpl>
  <card class="custom-template position-absolute">
    <div class="d-flex v2-pt-2 v2-pb-2">
      <radar-table-head-cell
        *ngFor="let column of columns"
        [column]="column"
      ></radar-table-head-cell>
    </div>

    <ng-container *ngFor="let x of [].constructor(3)">
      <div class="d-flex v2-pt-2 v2-pb-2">
        <skeleton-loader-small class="v2-mx-2" [height]="16" [width]="92"></skeleton-loader-small>
        <div class="d-flex w-98">
          <skeleton-loader-small class="v2-mx-2" [height]="16" [width]="82"></skeleton-loader-small>
        </div>
        <skeleton-loader-small class="v2-mx-2" [height]="16" [width]="45"></skeleton-loader-small>
        <div class="d-flex w-105">
          <skeleton-loader-small class="v2-mx-2" [height]="16" [width]="60"></skeleton-loader-small>
        </div>
        <div class="d-flex w-120">
          <skeleton-loader-small class="v2-mx-2" [height]="16" [width]="20"></skeleton-loader-small>
        </div>
        <skeleton-loader-small class="v2-mx-2" [height]="16" [width]="84"></skeleton-loader-small>
      </div>
    </ng-container>
  </card>
</ng-template>

<ng-template
  #customListTemplate
  let-items="items"
  let-selectMatch="selectMatch"
  let-hoveredItem="hoveredItem"
  let-setHoveredItem="setHoveredItem"
>
  <ng-container *ngIf="items.length; else emptyTemplate">
    <radar-table
      class="result-container v2-pt-2"
      [columns]="columns"
      [rows]="items"
      [hoveredRow]="hoveredItem"
      [stickHeaders]="true"
      (rowHover)="setHoveredItem($event.row)"
      (rowClick)="selectMatch($event.row)"
    >
    </radar-table>
  </ng-container>
</ng-template>

<ng-template #emptyTemplate>
  <card class="d-flex empty-state align-items-center">
    <img class="v2-mr-4" width="48" height="48" src="/assets/images/no-result.svg" />
    <typography-v2 look="caption"
      >There is no suitable Account for the selected parameters</typography-v2
    >
  </card>
</ng-template>
