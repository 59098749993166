<div>
  <checkbox-v2
    *ngFor="let option of props.options | formlySelectOptions:field | async"
    (check)="onChange(option.value, $event)"
    [label]="props.label"
    [checked]="isChecked(option)"
    [disabled]="!!(formControl.disabled || option.disabled)"
    [label]="option.label"
    [attr.data-id]="field.key + '_' + option.value"
    class="multi-container"
  ></checkbox-v2>
</div>
