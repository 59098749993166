<div
  class="d-flex datetime-input-wrapper position-relative"
  [ngClass]="{
      'focused': hasFocus,
      'hovered': hasHover,
      'has-error': formControl.invalid
     }"
>
  <input
    type="text"
    data-id="date-input"
    #dateInput
    (focusin)="hasFocus = true; hasDropdownFocus = false"
    (focusout)="hasFocus = false"
    (mouseenter)="hasHover = true"
    (mouseleave)="hasHover = false"
    [formControl]="dateInputModel"
    (keydown.enter)="onDateInputChange()"
    (change)="onDateInputChange()"
    class="date datetime-input"
    autocomplete="off"
  />
  <div class="position-relative w-100">
    <input
      data-id="time-input"
      type="text"
      (mouseenter)="hasHover = true"
      (mouseleave)="hasHover = false"
      (focusin)="hasFocus = true; showDD = true;"
      (focusout)="setTimeInputState()"
      [formControl]="timeInputModel"
      (keydown.enter)="onTimeInputChange(timeInputModel.value || '')"
      (change)="onTimeInputChange(timeInputModel.value || '')"
      class="time datetime-input"
      autocomplete="off"
    />

    <dropdown-v2
      data-id="time-dropdown"
      class="time-dropdown"
      look="just-items"
      [wideItemsContainer]="true"
      (mouseenter)="hasDropdownFocus = true"
      (mouseleave)="hasDropdownFocus = false"
      (itemSelect)="onDDTimeInputChange($event)"
      [collapsed]="!showDD"
      [items]="timeStepsDD"
    ></dropdown-v2>
    <button-icon-v2
      *ngIf="!props.required"
      class="position-absolute delete-button"
      key="trash"
      (click)="clearDate()"
    >
    </button-icon-v2>
  </div>

  <div class="separator position-absolute"></div>
</div>

<formly-validation-message
  data-id="date-validation"
  class="v2-mt-1"
  *ngIf="formControl.errors"
  [field]="field"
></formly-validation-message>

<bs-datepicker-inline
  data-id="datepicker-inline"
  class="v2-mt-4 d-block ms-auto me-auto"
  [bsValue]="formControl.value"
  [bsConfig]="{
    customTodayClass: 'rdr-today'
  }"
  (bsValueChange)="onDatepickerChange($event)"
></bs-datepicker-inline>

<hr />

<div>
  <button-v2
    data-id="preselect-after-5-hours"
    (click)="preselectDate('after-5-hours')"
    type="tertiary"
    look="full-width"
    class="d-flex w-100"
  >
    <typography-v2 look="caption-bold">In 5 hours</typography-v2>
    <typography-v2 look="caption" class="ms-auto"
      ><span class="text-black-50"
        >{{ preselected['after-5-hours'] | rdrDate: 'extended' }}</span
      ></typography-v2
    >
  </button-v2>
  <button-v2
    data-id="preselect-today"
    (click)="preselectDate('tomorrow')"
    type="tertiary"
    look="full-width"
    class="d-flex w-100"
  >
    <typography-v2 look="caption-bold">Tomorrow</typography-v2>
    <typography-v2 look="caption" class="ms-auto"
      ><span class="text-black-50"
        >{{ preselected.tomorrow | rdrDate: 'short' }}</span
      ></typography-v2
    >
  </button-v2>
  <button-v2
    data-id="preselect-tomorrow"
    (click)="preselectDate('after-tomorrow')"
    type="tertiary"
    look="full-width"
    class="d-flex w-100"
  >
    <typography-v2 look="caption-bold">Day after tomorrow </typography-v2>
    <typography-v2 look="caption" class="ms-auto"
      ><span class="text-black-50"
        >{{ preselected['after-tomorrow'] | rdrDate: 'short' }}</span
      ></typography-v2
    >
  </button-v2>
</div>

<hr />
