<typeahead-v3
  [placeholder]="props.placeholder || ''"
  (selectItem)="selectOption($event)"
  [selectedItem]="selectedItem"
  [items]="props.optionsService"
  [disabled]="props.disabled || form.disabled"
  [key]="field.key"
  [isFilter]="props.isFilter"
  [groupByField]="props.groupedFields ? 'groupBy' : ''"
  [filterCallback]="props.filterCallback"
  [fetchOnSearch]="props.asyncOptionsFetch"
></typeahead-v3>
