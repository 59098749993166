<div
  *ngFor="let currentField of field.fieldGroup; let i = index; let last = last"
  [attr.data-id]="'outer-' + currentField.dataId"
  [ngClass]="{ 'v2-mb-1': !last }"
  class="position-relative"
>
  <div class="field">
    <formly-field [field]="currentField"></formly-field>
  </div>

  <button-icon-v2
    [disabled]="field.props?.required === true && field.fieldGroup?.length === 1"
    (btnClick)="remove(i)"
    key="trash-v-2"
    class="v2-ml-1 position-absolute delete-btn"
    color="var(--color-red)"
    type="just-icon"
  ></button-icon-v2>
</div>

<div>
  <button-v2
    [disabled]="props.readonly"
    class="position-absolute add-btn"
    [attr.data-id]="props.addButtonDataid || ('add-' + key)"
    look="link"
    size="big"
    (click)="add()"
  >
    <typography-v2 look="caption-bold">{{ props.addButtonLabel || 'Add' }}</typography-v2>
  </button-v2>
</div>

<formly-validation-message
  *ngIf="fieldWithError"
  [field]="fieldWithError"
></formly-validation-message>
