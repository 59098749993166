<div [ngClass]="{ 'is-filter-search': props.isFilterSearch }">
  <input
    #inputRef
    class="text-truncate"
    autocomplete="off"
    [class.is-active]="isWide"
    radarOnEnter
    (focus)="onFocus()"
    (blur)="checkActiveState()"
    (radarOnEscape)="cancel()"
    [placeholder]="isWide ? field.props?.placeholder : 'Search'"
    [formControl]="formControl"
    [formlyAttributes]="field"
    [attr.data-id]="field.dataId"
  />

  <button-icon-v2
    type="header"
    key="close"
    class="close"
    color="var(--color-white-50)"
    (click)="cancel()"
  ></button-icon-v2>

  <rdr-svg-icon
    *ngIf="props.isFilterSearch; else globalSearchBtn"
    (click)="onFocus()"
    class="search-icon"
    key="search"
    color="var(--placeholder)"
  ></rdr-svg-icon>

  <ng-template #globalSearchBtn>
    <button-icon-v2
      type="header"
      key="search-v-2"
      (click)="onFocus()"
      data-id="open-search"
    ></button-icon-v2>
  </ng-template>
</div>
